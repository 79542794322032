.product {
    display: flex;
    flex-direction: column;
    border: 1px solid var(--tg-theme-button-color);
    padding: 15px;
    width: 170px;
}


.img {
    max-width: 200px;
    width: 100%;
    background: lightgray;
    height: 150px;
    margin-bottom: 10px;
    /* background: var(--tg-theme-button-color); */
}

.img img {
    width: 100%;
    height: 100%;
    max-height: 150px;
}


.description {
    padding: 10px 0;
    font-size: 0.8em;
}

.add-btn {
    width: 100%;
    margin: 10px 0;
}
.counter-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    margin: 5px 10px;
}

.counter {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 5px;
}